
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/*font-family: 'Sora', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Quicksand', sans-serif;*/

:root {
    --white: #FFFFFF;
    --blue: #3f5099;
    --orange: #f08223;
    --red: #e4294e;
    --green: #6ca66c;
    --yellow: #ffaa00;
    --gray-dark: #141414;
    --gray: #afafaf;
    --gray-light: #f7f7f7;
    --body: #141414;
    
    font-size: 14px;
}
/*.margemBody{
}*/
body {
  margin: 0;
  font-family: "Sora", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: var(--body);
}

*:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 transparent !important;
   }
  
p {
    margin: 0px 0px 10px 0px;
}
/*.card p {}*/

a {
	color: var(--blue);
}
a:hover {
    color: var(--gray);
}

a.color{
    color: var(--blue);
}
a.color-red{
    color: var(--red);
}
a.color-orange{
    color: var(--orange);
}

footer a {
    color: var(--gray);
}

/* MARGIN PADDING */
    .mt-100 {
        margin-top: 100px;
    }
    .py-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    #info.py-100 {
        padding-top: 85px;
        padding-bottom: 85px;
    }
/* end MARGIN PADDING */

.btn {
    white-space: normal;
    padding: .375rem 40px;
}
.btn-primary {
    background-color: var(--orange);
    font-weight: 700;
    border: 1px solid var(--orange);
    color: var(--white);
    border-radius: 30px;
}
.btn-primary:hover, .btn-primary:active,
.btn-primary.active, .open > .dropdown-toggle.btn-primary,
.btn-primary:focus, .btn-primary.focus{
    background-color: var(--red);
    border-color: var(--red);
    outline: none;
    color: var(--white);
    box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled{
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(0,123,255,.5);
}

#info .btn {
    padding: initial;
}
#info .btn-primary {
    background-color: var(--white);
    font-weight: 700;
    border: 1px solid var(--white);
    color: var(--blue);
    border-radius: 30px;
}
#info .btn-primary:hover, #info .btn-primary:active,
#info .btn-primary.active, #info .open > .dropdown-toggle.btn-primary,
#info .btn-primary:focus, #info .btn-primary.focus{
    background-color: var(--orange);
    border-color: var(--orange);
    outline: none;
    color: var(--white);
}

.btn-link:hover{
    color: var(--blue);
    text-decoration: underline;
}

button.btn-link:hover,
button.btn-link:focus{
    text-decoration: none;
}
.accordion .btn{
    background-color: transparent;
}

.accordion button.collapsed:before{
    font-family: "Font Awesome 5 Free";
    font-size: 0.7rem;
    font-weight: 900; 
    content: "\f067";
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    color: white;
    border-radius: 50%;
    padding: 2.5px 6px;
}
.accordion button:before{
    font-family: "Font Awesome 5 Free";
    font-size: 0.7rem;
    font-weight: 900; 
    content: "\f068";
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    color: white;
    border-radius: 50%;
    padding: 2.5px 6px;
}
.accordion button.btn-link.collapsed:before{
    background-color: var(--blue);
}
.accordion button.btn-link:before{
    background-color: var(--blue);
}
.accordion button.btn-link-red.collapsed:before{
    background-color: var(--red);
}
.accordion button.btn-link-red:before{
    background-color: var(--red);
}
.accordion button.btn-link-orange.collapsed:before{
    background-color: var(--orange);
}
.accordion button.btn-link-orange:before{
    background-color: var(--orange);
}

.maiusculas {
	text-transform: uppercase;
	font-weight: 500;
}
.titulo-primario, .titulo-secundario {
    color: var(--body);
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
}
.titulo-secundario{
    color: var(--orange);
    font-size: 20px;
}
.subtitulo-primario, .subtitulo-secundario{
    font-weight: 700;
    font-size: 16px;
    color: var(--gray-dark);
    line-height: 1.2;
}
.subtitulo-secundario{
    font-weight: 400;
    color: var(--gray);
}

blockquote {
    border-left: 5px solid var(--red);
    font-size: inherit;
    background-color: #f6f6f6;
    margin: 20px 0 20px 20px;
    padding: 1rem 1rem 0.5rem;
}
.limit-5-lines{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.limit-10-lines{
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.column-count-2{
    column-count: 2;
    column-gap: 30px;
}
@media (max-width: 820px) {
    .column-count-2{
        column-count: 1;
        column-gap: 30px;
    }
}

.bg-color-gray-light{
    background-color: var(--gray-light);
}
.bg-color-gray{
    background-color: var(--gray);
}
.bg-color-red{
    background-color: var(--red);
}
.bg-color-orange{
    background-color: var(--orange);
}
.bg-color-blue{
    background-color: var(--blue);
}
.bg-color-body{
    background-color: var(--body);
}
.bg-color-white{
    background-color: var(--white);
}

.parallax{
    position: relative;
    height: auto;
    background-position: right;
    background-size: cover;
    background-attachment: fixed;
    /*max-height: 700px;*/
}

.overlay-img {
    background: rgba(211, 215, 221, 0.8);
    width: 100%;
    height: 100%;
}

.loader {
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}



    .titulo-pp{
        font-size: 2rem;
        font-weight: 700;
    }

    .titulo-pp::after{
        height: 3px;
        width: 4rem;
        content: "";
        display: block;
        background-color: var(--orange);
        margin: auto;
        margin-top: 25px;
    }

/* INFORMAÇÕES DE APOIO */
    .card-header {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: var(--gray-light);
        border-bottom: 0px;
    }
    #info .card-header {
        padding: 0;
    }
/* end INFORMAÇÕES DE APOIO */

/* TESTEMUNHOS */
    .swiper-pagination-bullet-active{
        background-color: var(--body);
        /*margin: 0 5px;*/
    }
    .swiper-pagination-bullet{
        margin: 0 2px;
    }
/* end TESTEMUNHOS */

/* SEARCH */
    .limit-lines {
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
/* end SEARCH */


/* PAGINATION */
    .pagination {
        margin: 15px auto;
        display: flex;
        list-style: none;
        outline: none;
    }
    .pagination > .active > a{
        background-color: var(--body);
        border-color: var(--body);
        color: #fff;
    }
    .pagination > li > a{
        /*border: 1px solid var(--body);*/
        padding: 5px 10px;
        margin: auto 5px;
        outline: none;
        cursor: pointer;
    }
    .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
        background-color: var(--blue);
        border-color: var(--blue);
        outline: none;
    }
    .pagination > li > a, .pagination > li > span{
        color: var(--body);
    }
    .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
        border-radius: unset;
    }
/* end PAGINATION */

/* COOKIE */
    .containerCookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;
        background: var(--blue) !important;
        justify-content: center !important;
    }

    .messageCookie {
        margin: 0 0 10px;
        padding: 10px 20px;
        display: block;
        line-height: 1.2em;
        font-size: 0.85rem;
        text-align: justify;
        margin: 0;
        text-decoration: none;
        color: var(--white);
    }

    .messageCookie p {
        margin: 0;
    }

    .messageCookie a {
        color: white;
        text-decoration: none;
    }
    .messageCookie a:hover {
        text-decoration: underline;
    }

    .btn-cookies {
        background-color: var(--white) !important;
        font-weight: 700;
        border: 1px solid var(--white)  !important;
        color: var(--blue) !important;
        border-radius: 30px !important;
    }

    .btn-cookies:hover, .btn-cookies:active, .btn-cookies.active, .open > .dropdown-toggle.btn-cookies,
    .btn-cookies:focus, .btn-cookies.focus{
        background-color: var(--yellow)  !important;
        border-color: var(--yellow)  !important;
        outline: none !important;
        color: var(--white) !important;
    }
/* end COOKIE */

.card {
    transition: .5s;
    border: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    cursor: default;
}
.card:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

/* MODAL */
    /*.modal{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }*/
    .modal .close {
        /*font-size: 2rem;
		color: var(--gray-dark) !important;*/
        -webkit-transition: all 500ms linear;
		-ms-transition: all 500ms linear;
		transition: all 500ms linear;
    }

    .modal .close:hover,
    .modal .close:focus {
        color: var(--yellow) !important;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        transform: rotate(90deg);
    }
/* end MODAL */

/* TABS */
    .project-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color: var(--blue);
        background-color: transparent;
        border-color: transparent transparent #f3f3f3;
        border-bottom: 2px solid !important;
    }
    .project-tab .nav-link {
        border: 2px solid transparent;
        /*border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;*/
        color: var(--blue);
        font-weight: 600;
        transition: all 0.4s ease-in-out;
    }
    .project-tab .nav-link:hover {
        border: 2px solid transparent;
    }

	#activity_tabs .nav-tabs {
		border-bottom: none;
		border-radius: 40px;
		column-gap: 10px;
		row-gap: 10px;
	}
	#activity_tabs .nav-item h5 {
		font-size: 1rem;
	}
	#activity_tabs #tabs .nav-tabs .nav-item.show .nav-link, #activity_tabs .nav-tabs .nav-link.active {
		color: #ffffff;
		background-color: var(--blue);
		border-color: var(--blue);
		border-radius: 40px;
		border-bottom: transparent !important;
	}
	#activity_tabs .nav-link {
		border: 2px solid transparent;
		color: var(--blue);
		background-color: var(--gray-light);
		font-weight: 600;
		transition: all 0.4s ease-in-out;
		border-color: transparent;
		border-radius: 40px;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
	}
	#activity_tabs .nav-link:hover {
		color: #ffffff;
		background-color: var(--orange);
		border-color: transparent;
		border-radius: 40px;
	}
	#activity_tabs .nav-link:active {
		color: #ffffff;
		background-color: var(--red);
		border-color: transparent;
		border-radius: 40px;
	}


/* end TABS */


/* LIST */
    /*.div-content {}*/
    .img-list, .img-list2, .img-banner, .img-split {
        background-size: cover;
        background-position: center center;
        width: 350px;
        height: auto;
        aspect-ratio: auto 1 / 1;
        overflow: hidden;
        /*border: 1rem solid var(--white);*/

    }
    .img-banner {
        aspect-ratio: initial;
        width: initial;
    }
    .img-split {
        aspect-ratio: 9 / 16;
        max-width: 25vw;
    }
    .img-list img, .img-list2 img, .img-banner img, .img-split img, #accordionContent .card-body img{
        display: block;
        width: 100%;
        height: 100%;  
        object-fit: cover;
        cursor: -webkit-zoom-in;
        cursor: zoom-in;
    }
    #accordionContent .card-body img {
        background-size: cover;
        background-position: center center;
        width: 350px;
        height: auto;
        aspect-ratio: auto 1 / 1;
        overflow: hidden;
    }
    .img-list a img, .img-list2 a img, .img-banner a img, .img-split a img, #accordionContent .card-body a img {
        opacity: 1;
        transition: opacity 0.35s, transform 0.35s;
        transform: scale3d(1,1,1);
    }
    .img-list a img:hover, .img-list2 a img:hover, .img-banner a img:hover, .img-split a img:hover, #accordionContent .card-body a img:hover {
        opacity: 0.85;
        transform: scale3d(1.025,1.025,1);
    }

    .hover-animate:focus, .hover-animate:hover {
        transform: translate3d(0,-2px,0);
    }

    .hover-animate {
        transition: all .2s ease-in-out;
    }
/* end LIST */  

/* BTN e BOOTSTRAP ICONS */

    #webinar-cards .btn-text, #webinar-cards .btn-text-inv {
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0.75px;
        color: var(--white);
        transition: all 0.3s ease 0s;
        background-color: var(--orange);
        padding: 0.5rem 1.5rem;
        text-decoration: none;
        border-radius: 40px;
    }
    #webinar-cards .btn-text:hover {
        background-color: var(--red);
    }
    #webinar-cards .btn-text:active {
        background-color: var(--blue);
    }
    #webinar-cards .btn-text:active {
        background-color: #0062cc;
    }
    #webinar-cards .btn-text-inv {
        color: #0062cc;
        background-color: var(--white);
    }
    #webinar-cards .btn-text-inv:hover {
        color: var(--white);
        background-color: var(--blue);
    }

    .btn-inscription {
        font-size: small;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: var(--white);
        background-color: var(--red);
        padding: 0.5rem 1.5rem;
        border-radius: 0;
        transition: 0.4s;
        transform: translateY(0);
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn-inscription:hover, .btn-inscription:active {
        color: var(--white);
        background-color: var(--blue);
        transition : 0.4s;
        transform : translateY(-2px);
    }
    .btn-inscription:active{
        background-color: var(--orange);
    }

    .bi.bi-chevron-right, .bi.bi-chevron-left, .bi.bi-download {
		font-size: initial;
		margin-left: 5px;
		line-height: 0;
		-webkit-transition: all 0.4s ease-out;
		-moz-transition: all 0.4s ease-out;
		-o-transition: all 0.4s ease-out;
		transition: all 0.4s ease-out;
	}
	.bi.bi-chevron-left {
		margin-left: 0;
		margin-right: 5px;
	}
    
/* end BTN e BOOTSTRAP ICONS */

/* BADGES */

#webinar-cards .webinar-badge {
    position: absolute;
    top: 30%;
    right: 15px;
    max-width: 80px;
}
/* Media Queries */
    @media only screen and (max-width : 1920px) {}
    @media only screen and (max-width : 1680px) {
        #webinar-cards .webinar-badge {
            top: 34%;
            max-width: 90px;
        }
    }
    @media only screen and (max-width : 1400px) {
        #webinar-cards .webinar-badge {
            top: 27%;
            right: 10px;
            max-width: 90px;
        }
    }
    @media only screen and (max-width : 1200px) {
        #webinar-cards .webinar-badge {
            top: 21%;
            right: 5px;
            max-width: 75px;
        }
    }
    @media only screen and (max-width : 1180px) {
        #webinar-cards .webinar-badge {
            top: 22%;
            right: 5px;
            max-width: 70px;
        }
    }
    @media only screen and (max-width : 992px) {}
    @media only screen and (max-width : 820px) {
        #webinar-cards .webinar-badge {
            top: 30%;
        }
    }
    @media only screen and (max-width : 768px) {}
    @media only screen and (max-width : 576px) {
        #webinar-cards .webinar-badge {
            top: 42%;
            right: 5px;
            max-width: 100px;
        }
    }
    @media only screen and (max-width : 425px) {
        #webinar-cards .webinar-badge {
            top: 28%;
            right: 10px;
            max-width: 75px;
        }
    }
/* end Media Queries */

/* END BADGES */

/* PAGE 404 */
	#notfound {
		position:40 relative;
		height: 100vh;
	}
	#notfound .notfound {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.notfound {
		max-width: 850px;
		width: 100%;
		line-height: 1.4;
		text-align: center;
		padding: 15px;
	}
	.notfound .notfound-404 {
		position: relative;
		height: 220px;
	}
	.notfound .notfound-404 h1 {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: 150px;
		font-weight: 100;
		margin: 0px;
		background: linear-gradient(130deg, #f5a719, #ee8126);
		color:transparent;
		-webkit-background-clip: text;
		background-clip: text;
		text-transform: uppercase;
	}
	.notfound h2 {
		font-size: 33px;
		text-transform: uppercase;
		margin-top: 0px;
		margin-bottom: 25px;
		letter-spacing: 3px;
	}
	.notfound p {
		margin-top: 0px;
		margin-bottom: 25px;
	}
	.notfound a {
		font-weight: 500;
		text-decoration: none;
		border-radius: 40px;
	}
	.notfound-social>a {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		width: 40px;
		font-size: 14px;
		color: #ff6f68;
		border: 1px solid #efefef;
		border-radius: 50%;
		margin: 3px;
		-webkit-transition: 0.2s all;
		transition: 0.2s all;
	}
	.notfound-social>a:hover {
		color: #fff;
		background-color: #ff6f68;
		border-color: #ff6f68;
	}
	@media only screen and (max-width: 480px) {
		.notfound .notfound-404 {
			position: relative;
			height: 168px;
		}
		.notfound .notfound-404 h1 {
			font-size: 142px;
		}
		.notfound h2 {
			font-size: 22px;
		}
	}
/* end PAGE 404 */
