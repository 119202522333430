/*#navbar .navbar{
    background-color: white;
    box-shadow: rgb(0, 0, 0, 0.2) 0px 2px 2px;
}*/


.navbar .current_top {
    color: var(--blue) !important;
    font-weight: 500 !important;
    border-bottom: 2px solid var(--blue) !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
}

#navbar a{
    color: var(--gray-dark);
    font-weight: 500;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.button_no_border{    
    border: 0px;
    background:transparent;
    cursor: pointer;
}

.button_no_border:focus{    
    border: 0px;
    outline: 0px;
}

.navbar-light .navbar-toggler{
    border: 0;
}

.navbar-light .navbar-toggler:focus{
    outline: 0;
}

#navbar a:hover{
    color: var(--blue);
    font-weight: 500;
    border-bottom: 2px solid var(--blue);
}

#navbar a.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 0 solid transparent;
}

#navbar a.navbar-brand:hover{
    padding-bottom: 0px;
    border-bottom: 0px solid transparent;
}


.svg-logo{
    height: 58px;
    transition: all 0.4s ease-in-out;
}

/*#small-logo{
    display: none;
}*/

.svg-logo-small{
    height: 40px;
}

.nav-effect{
    -webkit-transition: background 1.5s;
    -moz-transition: background 1.5s;
    -ms-transition: background 1.5s;
    -o-transition: background 1.5s;
    transition: background 1.5s;
}

.h-0-transition{
    height: 0;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
    -ms-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
}

/* HAMBURGUER */
    .hamburguer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
/* end HAMBURGUER */

/*.navbar-expand-lg .navbar-collapse {
    background-color: rgb(255 255 255 / 35%);
}*/


@media (max-width: 992px) {
    .navbar-collapse{
        background-color: var(--white);
    }
}

@media only screen and (max-width: 1200px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
        margin-right: 0.75rem;
        margin-left: 0.75rem;
    }
}