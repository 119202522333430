@import url(https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
	font-family: 'Sora', sans-serif;
}
#slider .swiper-slide{
    width: 100%;
    height: calc(100vh);
    /*height: calc(80vh - 135px);
    margin-top: 10rem;*/
}
@media screen and (max-width: 1440px) {
    #slider .swiper-slide{
        height: calc(68vh);
    }
}
@media screen and (max-width: 1024px) {
    #slider .swiper-slide{
        height: calc(52vh);
    }
}
@media screen and (max-width: 992px) {
    #slider .swiper-slide{
        height: calc(50vh);
    }
}
@media screen and (max-width: 820px) {
    #slider .swiper-slide {
        height: calc(52vh);
    }
    /*#slider {
        margin-top: 100px;
    }*/
}
@media screen and (max-width: 768px) {
    #slider .swiper-slide{
        height: calc(41vh);
        /*height: calc(80vh - 135px);
        margin-top: 10rem;*/
    }
    #slider {
        margin-top: 68px;
    }
}
@media screen and (max-width: 425px) {
    #slider .swiper-slide{
        height: calc(28vh);
        /*height: calc(80vh - 135px);
        margin-top: 10rem;*/
    }
    #slider {
        margin-top: 68px;
    }
}

/*.swiper,
.swiper-container {
    width: 100%;
	height: 100%;
}*/

.swiper-button-next{
    /*background-image: none;
    right: -20px;
    left: auto;*/
    right: 30px !important;
    outline: none;
    color: var(--blue-light) !important;
}
.swiper-button-prev {
    /*background-image: none;
    left: -20px;
    right: auto;*/
    left: 30px !important;
    outline: none;
    color: var(--blue-light) !important;
}

.swiper-pagination-bullet {
    width: 0.8rem !important;
    height: 0.8rem !important;
    /*background: var(--white) !important;*/
    opacity: .7 !important;
    margin: 0 4px !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--orange) !important;
    opacity: 1 !important;
    outline: none;
}

/*#programas .swiper-pagination-bullet-active{
    background-color: var(--blue-light) !important;
}*/
/* TESTEMUNHOS */
    /*#opinion .swiper-pagination-bullet-active{
        background-color: var(--body) !important;;
        margin: 0 5px;
    }
    #opinion .swiper-pagination-bullet{
        margin: 0 2px;
    }*/

/* end TESTEMUNHOS */

.swiper-pagination-cartaz {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
    z-index: 10;
}

.swiper-pagination-opinion{
    position: relative;
    text-align: center;
    transition: .3s opacity;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
    z-index: 10;
    bottom: 0 !important;
}

#slider .swiper-button-next:after,
#slider .swiper-button-prev:after {
	font-size: 40px;
    color: var(--white);
    text-shadow: 0px 0px 4px rgb(0 0 0 / 25%), 0px 0px 20px rgb(255 255 255 / 10%);
}

.caption_top_center {
    position: absolute;
    top: 25%;
    right: 50%;
    left: 50%;
    width: 50%;
    text-align: center;
    padding: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.caption_top_left {
    position: absolute;
    top: 25%;
    left: 0;
    width: 50%;
    text-align: left;
    -webkit-transform: translate(30%, -50%);
    transform: translate(30%, -50%);
}

.caption_top_right {
    position: absolute;
    top: 25%;
    left: 0%;
    width: 50%;
    text-align: right;
    -webkit-transform: translate(70%, -50%);
    transform: translate(70%, -50%);
}

.caption_bottom_left {
    position: absolute;
    /*top: 50%;*/
    bottom: 35px;
    left: 0;
    width: 50%;
    text-align: left;
    -webkit-transform: translate(30%, -50%);
    transform: translate(30%, -50%);
}

.caption_bottom_right {
    position: absolute;
    bottom: 35px;
    left: 0%;
    width: 50%;
    text-align: right;
    -webkit-transform: translate(70%, -50%);
    transform: translate(70%, -50%);
}

.caption_bottom_center {
    position: absolute;
    right: auto;
    bottom: 35px;
    left: 50%;
    width: 50%;
    text-align: center;
    padding: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.caption_middle_left {
    position: absolute;
    top: 50%;
    bottom: auto;
    left: 0%;
    width: 50%;
    text-align: left;
    -webkit-transform: translate(30%, -50%);
    transform: translate(30%, -50%);
}

.caption_middle_right {
    position: absolute;
    top: 50%;
    bottom: auto;
    left: 0%;
    width: 50%;
    text-align: right;
    -webkit-transform: translate(70%, -50%);
    transform: translate(70%, -50%);
}

.caption_middle_center {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 50%;
    text-align: center;
    padding: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: 18px;
    background: #fff;
    overflow: hidden;
}

.swiper-pagination{
    bottom: 0;
    right: 0;
    left: 0;
}

/*.slide-image {
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    position: absolute;
    left: -5%;
    right: 0;
    width: 110%;
    height: 100%;
    background-position: 50% 45%;
    background-size: cover;
}*/

.slide-image {
    /*position: absolute;*/
    right: 0;
    height: 100%;
    background-position: 50% 50%;

    /*width: 110% !important;
    left: -5% !important;
    border-bottom-left-radius: 35%;
    border-bottom-right-radius: 35%;*/
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    will-change: transform;
}


/*.slide-title {
    font-size: 3vw;
    font-weight: 900;
    line-height: 1.3;
    color: var(--white);
    max-width: 50%;
    white-space: normal;
    word-break: break-word;
    text-transform: none;
    text-shadow: rgb(0 0 0 / 40%) 1px 1px 1px;
    z-index: 100;
}*/

.cartaz-titulo {
    /*font-family: 'Outfit', sans-serif;*/
    color: var(--white);
    font-size: 40px;
    text-transform: none;
    font-weight: 900;
    line-height: 1.3;
    margin: 0;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5), 0px 0px 20px rgba(255, 255, 255, 0.5);
    z-index: auto;
    padding: 0px;
    letter-spacing: 1.30469px;
    -webkit-filter: none;
            filter: none;
    white-space: normal;
    word-break: break-word;
}

.cartaz-subtitulo {
    color: var(--white);
    font-size: 	20px;
    line-height: 1em;
    font-weight: 400;
    margin: 0;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5), 0px 0px 20px #FFFFFF;
}

@media screen and (max-width: 992px) {
    .cartaz-titulo {
        font-size: 4vh;
    }
}
@media screen and (max-width: 767px) {
    .cartaz-titulo {
        font-size: 3vh;
    }

    .caption_top_center,
    .caption_top_left,
    .caption_top_right,
    .caption_middle_center,
    .caption_middle_left,
    .caption_middle_right,
    .caption_bottom_center,
    .caption_bottom_left,
    .caption_bottom_right {
        text-align: center;
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        width: 85%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    /*.swiper-button-next,
    .swiper-button-prev { 
        display: none !important;;
    }*/
}

.btn-left{
    position: absolute;
    top: 65%;
    left: 15%;
}

/* BUTTON SCROLL SLIDER */
.demo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: var(--cinza);
    text-decoration: none;
    transition: opacity .3s;
}
#slider-btn {
    padding-top: 60px;
}
#slider-btn span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid var(--white);
    border-radius: 50px;
    box-sizing: border-box;
    background-color: rgb(0 0 0 / 10%);
}
#slider-btn span::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: var(--white);
    border-radius: 100%;
    -webkit-animation: sdb10 2s infinite;
    animation: sdb10 2s infinite;
    box-sizing: border-box;
}

@-webkit-keyframes sdb10 {
    0% {
        -webkit-transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes sdb10 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
                transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

/*font-family: 'Sora', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Quicksand', sans-serif;*/

:root {
    --white: #FFFFFF;
    --blue: #3f5099;
    --orange: #f08223;
    --red: #e4294e;
    --green: #6ca66c;
    --yellow: #ffaa00;
    --gray-dark: #141414;
    --gray: #afafaf;
    --gray-light: #f7f7f7;
    --body: #141414;
    
    font-size: 14px;
}
/*.margemBody{
}*/
body {
  margin: 0;
  font-family: "Sora", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: #141414;
  color: var(--body);
}

*:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 transparent !important;
   }
  
p {
    margin: 0px 0px 10px 0px;
}
/*.card p {}*/

a {
	color: #3f5099;
	color: var(--blue);
}
a:hover {
    color: #afafaf;
    color: var(--gray);
}

a.color{
    color: #3f5099;
    color: var(--blue);
}
a.color-red{
    color: #e4294e;
    color: var(--red);
}
a.color-orange{
    color: #f08223;
    color: var(--orange);
}

footer a {
    color: #afafaf;
    color: var(--gray);
}

/* MARGIN PADDING */
    .mt-100 {
        margin-top: 100px;
    }
    .py-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    #info.py-100 {
        padding-top: 85px;
        padding-bottom: 85px;
    }
/* end MARGIN PADDING */

.btn {
    white-space: normal;
    padding: .375rem 40px;
}
.btn-primary {
    background-color: #f08223;
    background-color: var(--orange);
    font-weight: 700;
    border: 1px solid #f08223;
    border: 1px solid var(--orange);
    color: #FFFFFF;
    color: var(--white);
    border-radius: 30px;
}
.btn-primary:hover, .btn-primary:active,
.btn-primary.active, .open > .dropdown-toggle.btn-primary,
.btn-primary:focus, .btn-primary.focus{
    background-color: #e4294e;
    background-color: var(--red);
    border-color: #e4294e;
    border-color: var(--red);
    outline: none;
    color: #FFFFFF;
    color: var(--white);
    box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled{
    background-color: #f08223;
    background-color: var(--orange);
    border-color: #f08223;
    border-color: var(--orange);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(0,123,255,.5);
}

#info .btn {
    padding: 0;
    padding: initial;
}
#info .btn-primary {
    background-color: #FFFFFF;
    background-color: var(--white);
    font-weight: 700;
    border: 1px solid #FFFFFF;
    border: 1px solid var(--white);
    color: #3f5099;
    color: var(--blue);
    border-radius: 30px;
}
#info .btn-primary:hover, #info .btn-primary:active,
#info .btn-primary.active, #info .open > .dropdown-toggle.btn-primary,
#info .btn-primary:focus, #info .btn-primary.focus{
    background-color: #f08223;
    background-color: var(--orange);
    border-color: #f08223;
    border-color: var(--orange);
    outline: none;
    color: #FFFFFF;
    color: var(--white);
}

.btn-link:hover{
    color: #3f5099;
    color: var(--blue);
    text-decoration: underline;
}

button.btn-link:hover,
button.btn-link:focus{
    text-decoration: none;
}
.accordion .btn{
    background-color: transparent;
}

.accordion button.collapsed:before{
    font-family: "Font Awesome 5 Free";
    font-size: 0.7rem;
    font-weight: 900; 
    content: "\f067";
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    color: white;
    border-radius: 50%;
    padding: 2.5px 6px;
}
.accordion button:before{
    font-family: "Font Awesome 5 Free";
    font-size: 0.7rem;
    font-weight: 900; 
    content: "\f068";
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    color: white;
    border-radius: 50%;
    padding: 2.5px 6px;
}
.accordion button.btn-link.collapsed:before{
    background-color: #3f5099;
    background-color: var(--blue);
}
.accordion button.btn-link:before{
    background-color: #3f5099;
    background-color: var(--blue);
}
.accordion button.btn-link-red.collapsed:before{
    background-color: #e4294e;
    background-color: var(--red);
}
.accordion button.btn-link-red:before{
    background-color: #e4294e;
    background-color: var(--red);
}
.accordion button.btn-link-orange.collapsed:before{
    background-color: #f08223;
    background-color: var(--orange);
}
.accordion button.btn-link-orange:before{
    background-color: #f08223;
    background-color: var(--orange);
}

.maiusculas {
	text-transform: uppercase;
	font-weight: 500;
}
.titulo-primario, .titulo-secundario {
    color: #141414;
    color: var(--body);
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
}
.titulo-secundario{
    color: #f08223;
    color: var(--orange);
    font-size: 20px;
}
.subtitulo-primario, .subtitulo-secundario{
    font-weight: 700;
    font-size: 16px;
    color: #141414;
    color: var(--gray-dark);
    line-height: 1.2;
}
.subtitulo-secundario{
    font-weight: 400;
    color: #afafaf;
    color: var(--gray);
}

blockquote {
    border-left: 5px solid #e4294e;
    border-left: 5px solid var(--red);
    font-size: inherit;
    background-color: #f6f6f6;
    margin: 20px 0 20px 20px;
    padding: 1rem 1rem 0.5rem;
}
.limit-5-lines{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.limit-10-lines{
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.column-count-2{
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
}
@media (max-width: 820px) {
    .column-count-2{
        -webkit-column-count: 1;
                column-count: 1;
        grid-column-gap: 30px;
        -webkit-column-gap: 30px;
                column-gap: 30px;
    }
}

.bg-color-gray-light{
    background-color: #f7f7f7;
    background-color: var(--gray-light);
}
.bg-color-gray{
    background-color: #afafaf;
    background-color: var(--gray);
}
.bg-color-red{
    background-color: #e4294e;
    background-color: var(--red);
}
.bg-color-orange{
    background-color: #f08223;
    background-color: var(--orange);
}
.bg-color-blue{
    background-color: #3f5099;
    background-color: var(--blue);
}
.bg-color-body{
    background-color: #141414;
    background-color: var(--body);
}
.bg-color-white{
    background-color: #FFFFFF;
    background-color: var(--white);
}

.parallax{
    position: relative;
    height: auto;
    background-position: right;
    background-size: cover;
    background-attachment: fixed;
    /*max-height: 700px;*/
}

.overlay-img {
    background: rgba(211, 215, 221, 0.8);
    width: 100%;
    height: 100%;
}

.loader {
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    
}



    .titulo-pp{
        font-size: 2rem;
        font-weight: 700;
    }

    .titulo-pp::after{
        height: 3px;
        width: 4rem;
        content: "";
        display: block;
        background-color: #f08223;
        background-color: var(--orange);
        margin: auto;
        margin-top: 25px;
    }

/* INFORMAÇÕES DE APOIO */
    .card-header {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: #f7f7f7;
        background-color: var(--gray-light);
        border-bottom: 0px;
    }
    #info .card-header {
        padding: 0;
    }
/* end INFORMAÇÕES DE APOIO */

/* TESTEMUNHOS */
    .swiper-pagination-bullet-active{
        background-color: #141414;
        background-color: var(--body);
        /*margin: 0 5px;*/
    }
    .swiper-pagination-bullet{
        margin: 0 2px;
    }
/* end TESTEMUNHOS */

/* SEARCH */
    .limit-lines {
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
/* end SEARCH */


/* PAGINATION */
    .pagination {
        margin: 15px auto;
        display: flex;
        list-style: none;
        outline: none;
    }
    .pagination > .active > a{
        background-color: #141414;
        background-color: var(--body);
        border-color: #141414;
        border-color: var(--body);
        color: #fff;
    }
    .pagination > li > a{
        /*border: 1px solid var(--body);*/
        padding: 5px 10px;
        margin: auto 5px;
        outline: none;
        cursor: pointer;
    }
    .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
        background-color: #3f5099;
        background-color: var(--blue);
        border-color: #3f5099;
        border-color: var(--blue);
        outline: none;
    }
    .pagination > li > a, .pagination > li > span{
        color: #141414;
        color: var(--body);
    }
    .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
        border-radius: unset;
    }
/* end PAGINATION */

/* COOKIE */
    .containerCookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;
        background: #3f5099 !important;
        background: var(--blue) !important;
        justify-content: center !important;
    }

    .messageCookie {
        margin: 0 0 10px;
        padding: 10px 20px;
        display: block;
        line-height: 1.2em;
        font-size: 0.85rem;
        text-align: justify;
        margin: 0;
        text-decoration: none;
        color: #FFFFFF;
        color: var(--white);
    }

    .messageCookie p {
        margin: 0;
    }

    .messageCookie a {
        color: white;
        text-decoration: none;
    }
    .messageCookie a:hover {
        text-decoration: underline;
    }

    .btn-cookies {
        background-color: #FFFFFF !important;
        background-color: var(--white) !important;
        font-weight: 700;
        border: 1px solid #FFFFFF  !important;
        border: 1px solid var(--white)  !important;
        color: #3f5099 !important;
        color: var(--blue) !important;
        border-radius: 30px !important;
    }

    .btn-cookies:hover, .btn-cookies:active, .btn-cookies.active, .open > .dropdown-toggle.btn-cookies,
    .btn-cookies:focus, .btn-cookies.focus{
        background-color: #ffaa00  !important;
        background-color: var(--yellow)  !important;
        border-color: #ffaa00  !important;
        border-color: var(--yellow)  !important;
        outline: none !important;
        color: #FFFFFF !important;
        color: var(--white) !important;
    }
/* end COOKIE */

.card {
    transition: .5s;
    border: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    cursor: default;
}
.card:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

/* MODAL */
    /*.modal{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }*/
    .modal .close {
        /*font-size: 2rem;
		color: var(--gray-dark) !important;*/
		transition: all 500ms linear;
    }

    .modal .close:hover,
    .modal .close:focus {
        color: #ffaa00 !important;
        color: var(--yellow) !important;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
/* end MODAL */

/* TABS */
    .project-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color: #3f5099;
        color: var(--blue);
        background-color: transparent;
        border-color: transparent transparent #f3f3f3;
        border-bottom: 2px solid !important;
    }
    .project-tab .nav-link {
        border: 2px solid transparent;
        /*border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;*/
        color: #3f5099;
        color: var(--blue);
        font-weight: 600;
        transition: all 0.4s ease-in-out;
    }
    .project-tab .nav-link:hover {
        border: 2px solid transparent;
    }

	#activity_tabs .nav-tabs {
		border-bottom: none;
		border-radius: 40px;
		grid-column-gap: 10px;
		-webkit-column-gap: 10px;
		        column-gap: 10px;
		grid-row-gap: 10px;
		row-gap: 10px;
	}
	#activity_tabs .nav-item h5 {
		font-size: 1rem;
	}
	#activity_tabs #tabs .nav-tabs .nav-item.show .nav-link, #activity_tabs .nav-tabs .nav-link.active {
		color: #ffffff;
		background-color: #3f5099;
		background-color: var(--blue);
		border-color: #3f5099;
		border-color: var(--blue);
		border-radius: 40px;
		border-bottom: transparent !important;
	}
	#activity_tabs .nav-link {
		border: 2px solid transparent;
		color: #3f5099;
		color: var(--blue);
		background-color: #f7f7f7;
		background-color: var(--gray-light);
		font-weight: 600;
		transition: all 0.4s ease-in-out;
		border-color: transparent;
		border-radius: 40px;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
	}
	#activity_tabs .nav-link:hover {
		color: #ffffff;
		background-color: #f08223;
		background-color: var(--orange);
		border-color: transparent;
		border-radius: 40px;
	}
	#activity_tabs .nav-link:active {
		color: #ffffff;
		background-color: #e4294e;
		background-color: var(--red);
		border-color: transparent;
		border-radius: 40px;
	}


/* end TABS */


/* LIST */
    /*.div-content {}*/
    .img-list, .img-list2, .img-banner, .img-split {
        background-size: cover;
        background-position: center center;
        width: 350px;
        height: auto;
        aspect-ratio: auto 1 / 1;
        overflow: hidden;
        /*border: 1rem solid var(--white);*/

    }
    .img-banner {
        aspect-ratio: initial;
        width: auto;
        width: initial;
    }
    .img-split {
        aspect-ratio: 9 / 16;
        max-width: 25vw;
    }
    .img-list img, .img-list2 img, .img-banner img, .img-split img, #accordionContent .card-body img{
        display: block;
        width: 100%;
        height: 100%;  
        object-fit: cover;
        cursor: zoom-in;
    }
    #accordionContent .card-body img {
        background-size: cover;
        background-position: center center;
        width: 350px;
        height: auto;
        aspect-ratio: auto 1 / 1;
        overflow: hidden;
    }
    .img-list a img, .img-list2 a img, .img-banner a img, .img-split a img, #accordionContent .card-body a img {
        opacity: 1;
        transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
        -webkit-transform: scale3d(1,1,1);
                transform: scale3d(1,1,1);
    }
    .img-list a img:hover, .img-list2 a img:hover, .img-banner a img:hover, .img-split a img:hover, #accordionContent .card-body a img:hover {
        opacity: 0.85;
        -webkit-transform: scale3d(1.025,1.025,1);
                transform: scale3d(1.025,1.025,1);
    }

    .hover-animate:focus, .hover-animate:hover {
        -webkit-transform: translate3d(0,-2px,0);
                transform: translate3d(0,-2px,0);
    }

    .hover-animate {
        transition: all .2s ease-in-out;
    }
/* end LIST */  

/* BTN e BOOTSTRAP ICONS */

    #webinar-cards .btn-text, #webinar-cards .btn-text-inv {
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0.75px;
        color: #FFFFFF;
        color: var(--white);
        transition: all 0.3s ease 0s;
        background-color: #f08223;
        background-color: var(--orange);
        padding: 0.5rem 1.5rem;
        text-decoration: none;
        border-radius: 40px;
    }
    #webinar-cards .btn-text:hover {
        background-color: #e4294e;
        background-color: var(--red);
    }
    #webinar-cards .btn-text:active {
        background-color: #3f5099;
        background-color: var(--blue);
    }
    #webinar-cards .btn-text:active {
        background-color: #0062cc;
    }
    #webinar-cards .btn-text-inv {
        color: #0062cc;
        background-color: #FFFFFF;
        background-color: var(--white);
    }
    #webinar-cards .btn-text-inv:hover {
        color: #FFFFFF;
        color: var(--white);
        background-color: #3f5099;
        background-color: var(--blue);
    }

    .btn-inscription {
        font-size: small;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #FFFFFF;
        color: var(--white);
        background-color: #e4294e;
        background-color: var(--red);
        padding: 0.5rem 1.5rem;
        border-radius: 0;
        transition: 0.4s;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn-inscription:hover, .btn-inscription:active {
        color: #FFFFFF;
        color: var(--white);
        background-color: #3f5099;
        background-color: var(--blue);
        transition : 0.4s;
        -webkit-transform : translateY(-2px);
                transform : translateY(-2px);
    }
    .btn-inscription:active{
        background-color: #f08223;
        background-color: var(--orange);
    }

    .bi.bi-chevron-right, .bi.bi-chevron-left, .bi.bi-download {
		font-size: medium;
		font-size: initial;
		margin-left: 5px;
		line-height: 0;
		transition: all 0.4s ease-out;
	}
	.bi.bi-chevron-left {
		margin-left: 0;
		margin-right: 5px;
	}
    
/* end BTN e BOOTSTRAP ICONS */

/* BADGES */

#webinar-cards .webinar-badge {
    position: absolute;
    top: 30%;
    right: 15px;
    max-width: 80px;
}
/* Media Queries */
    @media only screen and (max-width : 1920px) {}
    @media only screen and (max-width : 1680px) {
        #webinar-cards .webinar-badge {
            top: 34%;
            max-width: 90px;
        }
    }
    @media only screen and (max-width : 1400px) {
        #webinar-cards .webinar-badge {
            top: 27%;
            right: 10px;
            max-width: 90px;
        }
    }
    @media only screen and (max-width : 1200px) {
        #webinar-cards .webinar-badge {
            top: 21%;
            right: 5px;
            max-width: 75px;
        }
    }
    @media only screen and (max-width : 1180px) {
        #webinar-cards .webinar-badge {
            top: 22%;
            right: 5px;
            max-width: 70px;
        }
    }
    @media only screen and (max-width : 992px) {}
    @media only screen and (max-width : 820px) {
        #webinar-cards .webinar-badge {
            top: 30%;
        }
    }
    @media only screen and (max-width : 768px) {}
    @media only screen and (max-width : 576px) {
        #webinar-cards .webinar-badge {
            top: 42%;
            right: 5px;
            max-width: 100px;
        }
    }
    @media only screen and (max-width : 425px) {
        #webinar-cards .webinar-badge {
            top: 28%;
            right: 10px;
            max-width: 75px;
        }
    }
/* end Media Queries */

/* END BADGES */

/* PAGE 404 */
	#notfound {
		position:40 relative;
		height: 100vh;
	}
	#notfound .notfound {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.notfound {
		max-width: 850px;
		width: 100%;
		line-height: 1.4;
		text-align: center;
		padding: 15px;
	}
	.notfound .notfound-404 {
		position: relative;
		height: 220px;
	}
	.notfound .notfound-404 h1 {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: 150px;
		font-weight: 100;
		margin: 0px;
		background: linear-gradient(130deg, #f5a719, #ee8126);
		color:transparent;
		-webkit-background-clip: text;
		background-clip: text;
		text-transform: uppercase;
	}
	.notfound h2 {
		font-size: 33px;
		text-transform: uppercase;
		margin-top: 0px;
		margin-bottom: 25px;
		letter-spacing: 3px;
	}
	.notfound p {
		margin-top: 0px;
		margin-bottom: 25px;
	}
	.notfound a {
		font-weight: 500;
		text-decoration: none;
		border-radius: 40px;
	}
	.notfound-social>a {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		width: 40px;
		font-size: 14px;
		color: #ff6f68;
		border: 1px solid #efefef;
		border-radius: 50%;
		margin: 3px;
		transition: 0.2s all;
	}
	.notfound-social>a:hover {
		color: #fff;
		background-color: #ff6f68;
		border-color: #ff6f68;
	}
	@media only screen and (max-width: 480px) {
		.notfound .notfound-404 {
			position: relative;
			height: 168px;
		}
		.notfound .notfound-404 h1 {
			font-size: 142px;
		}
		.notfound h2 {
			font-size: 22px;
		}
	}
/* end PAGE 404 */

footer .row {
	justify-content: center;
}

footer .address {
	cursor: default;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
footer .h6, footer h6 {
    font-size: 0.875rem;
	line-height: 1.5;
}

footer span, footer a {
	font-size: 0.75rem;
	display: flex;
	align-items: flex-start;
}

footer span.logosfooter {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
}
span.logos, footer span.logosfooter.redes {
    justify-content: flex-start;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
}
footer .copyright span {
    display: inherit;
}

.current_bottom{
    text-decoration: underline;
}

img.light-logo.svg-logo.img-fluid {
	-webkit-filter: brightness(0) invert(1);
	        filter: brightness(0) invert(1);
}

textarea{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus, textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #495057;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(73,80,87,.25);
}

/* APP FORM */
#app_form .control-label{
    padding: 0 10px;
    position: absolute;
    top: -6px;
    background-color: white;
    left: 30px;
    font-size: 0.7rem;
    font-weight: 700;
    z-index: 100;
}
.custom-file-label {
    width: 98%;
    border-radius: 0;
    cursor: pointer;
    left: 15px;
}
.custom-file-label:after {
    content: "Carregar";
    font-weight: 700;
    /*border-radius: 0;
    border-color: var(--body);
    color: #FFFFFF;
    background-color: var(--body);
    cursor: pointer;
    border-left: 10px solid #fff;*/
}

#app_form button.remove-file{
    border: 2px solid var(--body);
    border-radius: 50%;
    background-color: white;
    font-size: 11px;
}

.overflow-input{
    overflow: hidden;
    width: 98%;
    padding-right: 132px;
    white-space: nowrap;
}
/*#navbar .navbar{
    background-color: white;
    box-shadow: rgb(0, 0, 0, 0.2) 0px 2px 2px;
}*/


.navbar .current_top {
    color: var(--blue) !important;
    font-weight: 500 !important;
    border-bottom: 2px solid var(--blue) !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
}

#navbar a{
    color: var(--gray-dark);
    font-weight: 500;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.button_no_border{    
    border: 0px;
    background:transparent;
    cursor: pointer;
}

.button_no_border:focus{    
    border: 0px;
    outline: 0px;
}

.navbar-light .navbar-toggler{
    border: 0;
}

.navbar-light .navbar-toggler:focus{
    outline: 0;
}

#navbar a:hover{
    color: var(--blue);
    font-weight: 500;
    border-bottom: 2px solid var(--blue);
}

#navbar a.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 0 solid transparent;
}

#navbar a.navbar-brand:hover{
    padding-bottom: 0px;
    border-bottom: 0px solid transparent;
}


.svg-logo{
    height: 58px;
    transition: all 0.4s ease-in-out;
}

/*#small-logo{
    display: none;
}*/

.svg-logo-small{
    height: 40px;
}

.nav-effect{
    transition: background 1.5s;
}

.h-0-transition{
    height: 0;
    transition: all 2s ease-in-out;
}

/* HAMBURGUER */
    .hamburguer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
/* end HAMBURGUER */

/*.navbar-expand-lg .navbar-collapse {
    background-color: rgb(255 255 255 / 35%);
}*/


@media (max-width: 992px) {
    .navbar-collapse{
        background-color: var(--white);
    }
}

@media only screen and (max-width: 1200px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
        margin-right: 0.75rem;
        margin-left: 0.75rem;
    }
}
/*
 * CKEditor 5 (v30.0.0) content styles.
 * Generated on Mon, 04 Oct 2021 11:08:14 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

 :root {
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-color-table-caption-background: hsl(0, 0%, 97%);
    --ck-color-table-caption-text: hsl(0, 0%, 20%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: hsl(0, 0%, 20%);
    color: var(--ck-color-image-caption-text);
    background-color: hsl(0, 0%, 97%);
    background-color: var(--ck-color-image-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
    max-width: calc(100% - 1.5em);
    max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
    clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
    float: right;
    margin-left: 1.5em;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
    float: left;
    margin-right: 1.5em;
    margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
    float: right;
    margin-left: 1.5em;
    margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
    margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
    margin-top: calc(1.5em / 2);
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: calc(1.5em / 2);
    margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
    margin-right: calc(1.5em / 2);
    margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
    margin-left: calc(1.5em / 2);
    margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
    font-size: .7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
    font-size: .85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
    font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
    font-size: 1.8em;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
    background-color: hsl(60, 97%, 73%);
    background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
    background-color: hsl(120, 93%, 68%);
    background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
    background-color: hsl(345, 96%, 73%);
    background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
    background-color: hsl(201, 97%, 72%);
    background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
    color: hsl(0, 85%, 49%);
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
    color: hsl(112, 100%, 27%);
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
} */
/* ckeditor5-image/theme/image.css */
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
    display: flex;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
    width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
    display: block;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: hsl(0, 0%, 20%);
    color: var(--ck-color-table-caption-text);
    background-color: hsl(0, 0%, 97%);
    background-color: var(--ck-color-table-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
    margin: 0.9em auto;
    display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
    text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
    text-align: left;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed hsl(0, 0%, 77%);
    width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
    position: relative;
    z-index: 1;
    padding: .3em .6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
    list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
    margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
    margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: 16px;
    width: var(--ck-todo-list-checkmark-size);
    height: 16px;
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc( 16px / 3 );
    left: calc( var(--ck-todo-list-checkmark-size) / 3 );
    top: calc( 16px / 5.3 );
    top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    width: calc( 16px / 5.3 );
    width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    height: calc( 16px / 2.6 );
    height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc( 16px / 8 ) calc( 16px / 8 ) 0;
    border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
    font-style: italic;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
    background: hsla(341, 100%, 30%, 0.1);
    background: var(--ck-color-mention-background);
    color: hsl(341, 100%, 30%);
    color: var(--ck-color-mention-text);
}
@media print {
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break {
        padding: 0;
    }
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break::after {
        display: none;
    }
}

/*.normal .content-body .div-image {
    max-height: 40vh;
    overflow: hidden;
}

.list .div-text, .list2 .div-text {
    display: flow-root;
}*/

/* BLOCKQUOTE */
	blockquote{		
		font-size: 0.875em;
		width:auto;
		margin: 1rem calc(40px + 1rem);
		font-style:italic;
		padding: 1.2em 1.2em 1.2em 3.25em;
		border-left: 8px solid var(--blue-light);
		line-height:1.6;
		position: relative;
		background:var(--gray-light);
	}

	blockquote::before{
		font-family:Arial;
		content: "\201C";
		color:var(--blue-light);
		font-size:4em;
		position: absolute;
		left: 10px;
		top:-10px;
	}

	blockquote::after{
		content: '';
	}

	blockquote span.legenda{
		font-size: smaller;
		display:block;
		font-style: normal;
		font-weight: bold;
		margin-top:1em;
	}

	blockquote p {
		margin-bottom: 0;
	}

	@media only screen and (max-width : 768px) {
		blockquote {
			width: auto;
			margin: 1.5rem auto;
		}
	}
/* end BLOCKQUOTE */

#insights .insight-item, #news-cards .new-item, .insights-cards .card, .news-cards .card {
    box-sizing: content-box;
    min-height: 200px;
    box-shadow: 0px 2px 12px rgb(0 0 0 / 8%);
    position: relative;
    background: #fff;
    overflow: hidden;
}

#insights .insight-item .insight-img, #news-cards .new-item .new-img, .insights-cards .card .div-image, .news-cards .card .div-image {
    background-repeat: no-repeat;
    background-color: var(--gray-light);
    object-fit: cover;
    object-position: 50% 50%;
}

#insights .insight-item .insight-img img, #news-cards .new-item .new-img img, .insights-cards .card .div-image img, .news-cards .card .div-image img{
    object-fit: cover;
    aspect-ratio: 3/2;
}

#insights-cards .card-title, #news-cards .card-title, #webinar-cards .card-title {
    color: var(--body);
    font-weight: 800;
    font-size: 18px;
    line-height: 1.35;
}
#partners-cards .card-title {
    font-size: small;
    color: #54595f;
    line-height: 1.35;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.25px;
}

#partners-cards .card-title::before {
    height: 2px;
    width: 3rem;
    content: "";
    display: block;
    background-color: var(--orange);
    margin: auto;
    margin-bottom: 20px;
}


#partners-cards .partner-item {
    box-sizing: content-box;
    min-height: 200px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    position: relative;
    background: #fff;
    overflow: hidden;
}

#partners-cards .partner-item .partner-img{
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: 50% 50%;
    aspect-ratio: auto 4 / 3;
}

#partners-cards .partner-item .partner-img img {
    min-height: 100%;
    object-fit: cover;
}
#partners-cards .hover-animate:focus, #partners-cards .hover-animate:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

#insights-cards .card-text p, #news-cards .card-text p {
    margin-bottom: 0;
    font-size: 0.875em;
}
#insights-cards .card-text, #news-cards .card-text, #webinar-cards .card-text {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.insights-cards .titulo-pp::after, .news-cards .titulo-pp::after, .partners-cards .titulo-pp::after, .webinar-cards .titulo-pp::after {
    margin-bottom: 2.5rem;
}
.card-body {
    padding: 1.25rem;
}
.card-footer {
    padding-right: inherit;
    padding-left: inherit;
}

.partners-cards .card-body {
    padding: 0 1.25rem;
}
.partners-cards .card-footer {
    border: transparent;
    background-color: transparent;
}

.routes-cards .card-footer {
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,0);
}

.routes-cards .hover-animate:focus, .routes-cards .hover-animate:hover {
    -webkit-transform: translate3d(0,-2px,0);
            transform: translate3d(0,-2px,0);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
/*.div-image {
    cursor: zoom-in;
}*/
.accordion .content-body .div-image img, .list .content-body .div-image img, .list2 .content-body .div-image img, .normal .content-body .div-image img {
    max-height: 50vh;
    /*min-height: 350px;*/
    width: 100%;
    object-fit: cover;
    object-position: 85% 50%;
    cursor: zoom-in;
}
.normal .content-body .div-image img {
    cursor: default;
}

/*.accordion .div-image, .list .div-image, .list2 .div-image, .normal .div-image {
    border: 1rem solid var(--white);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}*/

.content-body .div-content .title {
    color: var(--cinza-escuro);
    font-weight: 400;
    font-size: 1.20rem;
    text-transform: uppercase;
    position: relative;
    padding-left: 60px;
}

.content-body .div-content .title:after {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 5px;
    width: 3rem;
    background-color: var(--laranja);
    content: "";
}

.content-body .iso-nav button {
    border-radius: 0;
}

.content-body .iso-nav button.active,
.content-body .iso-nav button:hover {
    background-color: var(--cinza-escuro) !important;
    border-color: var(--cinza-escuro) !important;
    outline: none !important;
    color: #FFF !important;
}

.content-body .div-scroll div {
    position: absolute;
    bottom: 20px;
    left: 50%;
    padding-top: 60px;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: var(--cinza);
    text-decoration: none;
    transition: opacity .3s;
}

.content-body .div-scroll div:hover {
    opacity: 0.5;
}

.content-body .div-scroll div span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid var(--cinza);
    border-radius: 50px;
    box-sizing: border-box;
}

.content-body .div-scroll div span:before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: var(--cinza);
    border-radius: 100%;
    -webkit-animation: scroll-dott 2s infinite;
    animation: scroll-dott 2s infinite;
    box-sizing: border-box;
}

.content-body .div-items .item {
    width: 23%;
    padding: 1%;
    float: left;
}

.content-body .div-items .loading {
    clear: both;
}

.content-list-item {
    border-top: 1px solid #dee2e6;
}


@-webkit-keyframes scroll-dott {
    0% {
        -webkit-transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes scroll-dott {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
                transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}


/* ACCORDION PAGE */




.element{
    float: right;
    justify-content: center;
    shape-margin: 7em;
}

.accordion .accordion-title {
    display: block;
    position: relative;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 25px;
    cursor: pointer;
}

main.accordion .card-header {
    background-color: var(--gray-light);
    border-bottom: 1px solid #dee2e6;
}

main.accordion .card-header button {
     color: var(--body);
    display: block;
    position: relative;
    font-size: 1em;
    line-height: 24px;
    font-weight: 400;
    padding-right: 25px;
    cursor: pointer;
}

main.accordion .card-header button .bi {
    color: var(--blue-dark);
    font-size: 1.5rem;
    transition: all 0.4s ease-in-out;
}

main.accordion .card-header button[aria-expanded="true"] .bi.bi-chevron-down {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

/* END ACCORDION PAGE */

.content-body .div-pdf-view canvas {
    width: 100% !important;
    height: auto !important;
}


/* NOTÍCIAS */
    .div-source {
        font-size: 0.875rem;
        line-height: 2;
    }
    .div-source span:last-child {
        padding-right: 0;
    }
    /*.div-source span:nth-last-child(-n+1) {
        font-style: italic;
        color: var(--gray);
    }*/
    .div-source span:not(:last-child):after{
        padding: 0 0.5rem;
        color: var(--orange);
        content: "|";
    }

    /*.div-source img {
        filter: invert(67%) sepia(30%) saturate(4537%) hue-rotate(343deg) brightness(96%) contrast(97%);
    }*/

    .div-abstract {
        font-size: 1.125em;
        font-weight: 500;
        word-break: break-word;
    }


    .div-gallery .subtitulo-pp{
        color: var(--body);
        font-weight: 700;
        font-size: 18px;
        text-align: center;
    }
    .div-gallery .subtitulo-pp::after{
        height: 3px;
        width: 4rem;
        content: "";
        display: block;
        background-color: var(--orange);
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1.25rem;
    }

    .div-gallery img {
        aspect-ratio: 1/1;
        object-fit: cover;
    }

    .div-last {
        position: -webkit-sticky;
        position: sticky;
        top: 7.2rem;
        right: 0;
        z-index: 2;
        height: -webkit-max-content;
        height: max-content;
        overflow-y: hidden;
        justify-content: center;
    }
    .div-last .subtitulo-pp{
        color: var(--body);
        font-weight: 700;
        font-size: 18px;
        text-align: center;
    }

    .div-last .subtitulo-pp::after{
        height: 3px;
        width: 4rem;
        content: "";
        display: block;
        background-color: var(--orange);
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1.25rem;
    }

    .div-last a {
        color: var(--body);
        font-weight: 600;
        font-size: 14px;
    }

    .div-last a:hover{
        text-decoration: none;
    }


    /*.div-last .div-image {}*/

    .div-last .div-image img {
        aspect-ratio: 16 / 9;
        object-fit: cover;
    }

    .div-last .btn-text-inv, .div-last .btn-text-inv a {
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.75px;
        text-decoration: none;
        color: var(--white);
        background-color: var(--blue);
        padding: 0.5rem 2rem 0.5rem 1.5rem;
        border-radius: 40px;
        display: inline-flex;
        transition: all 0.3s ease 0s;
        justify-content: center;
        align-items: center;
    }
    .div-last .btn-text-inv:hover, .div-last .btn-text-inv a:hover, .div-last .btn-text-inv:hover i {
        background-color: var(--orange);
        color: var(--white);
        transition: all 0.3s ease 0s;
    }
    .div-last .btn-text-inv:active, .div-last .btn-text-inv a:active, .div-last .btn-text-inv:active i {
        background-color: var(--red);
        color: var(--white);
        transition: all 0.3s ease 0s;
    }
    .div-last .btn-text-inv:hover i, .div-last .btn-text-inv:active i{
        background-color: transparent;
    }

    .div-last .bi.bi-chevron-right,
    .div-last .bi.bi-chevron-left,
    .div-last .bi.bi-download {
        background-color: transparent;
        color: var(--white);
        transition: all 0.3s ease 0s;
    }
    
    .div-last .div-text{
        font-weight: 700;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
    }
    .div-last .insight-item {
        background-color: #FFF;
    }
    .div-last .div-source {
        font-weight: 400;
    }

    .div-content .div-image img {
        aspect-ratio: 2/1;
        object-fit: cover;
        overflow: hidden;
    }

    .insight .div-content .div-image {
        overflow: hidden;
    }
    .insight .div-content .div-image img {
        /*cursor: zoom-in;*/
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    }


    .insight .div-content .div-image img:hover {
        -webkit-transform: scale3d(1.05,1.05,1), opacity 0.35s, transform 0.35s;
                transform: scale3d(1.05,1.05,1), opacity 0.35s, transform 0.35s;
        transition: all .2s ease-in-out;
    }


/* END NOTÍCIAS */

/* PARTNER */
    #partner .div-text p:last-child {
        margin-bottom: 0;
    }
    #partner p a {
        color: var(--body);
    }
    #partner p a:hover {
        color: var(--orange);
    }
    #partner .partner-item {
        box-sizing: content-box;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        position: relative;
        overflow: hidden;
    }
    #partner figure {
        aspect-ratio: 1/1;
        overflow: hidden;
    }
    #partner figure img {
        min-height: 100%;
        object-fit: cover;
    }
/* END PARTNER */

/* MEDIA QUERIE */   
    @media only screen and (max-width: 820px){
        .div-last {
            top: 8.25rem;
        }
    }
    @media only screen and (max-width: 768px){
        .div-last {
            position: static;
            position: initial;
            box-shadow: none;
            height: auto;
            margin-bottom: 30px;
        }
        .div-last .insight-item {
            box-shadow: none !important;
        }
    }
/* END MEDIA QUERY */


/*  ACTIVITIES */
    #activity_webinar .div-content .titulo-primario, #activity_webinar .div-content .titulo-secundario, #activity_consultoria .div-content .titulo-primario, #activity_consultoria .div-content .titulo-secundario {
        /*font-family: 'Quicksand', sans-serif;*/
        color: var(--body);
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1.3;
    }
    #activity_webinar .div-content .titulo-secundario, #activity_consultoria .div-content .titulo-secundario{
        color: var(--orange);
        font-size: 20px;
    }
    #activity_webinar .div-content .subtitulo-primario, #activity_webinar .div-content .subtitulo-secundario, #activity_consultoria .div-content .subtitulo-primario, #activity_consultoria .div-content .subtitulo-secundario {
        /*font-family: 'Quicksand', sans-serif;*/
        font-weight: 700;
        font-size: 1.125rem;
        color: var(--gray-dark);
        line-height: 1.25;
    }
    #activity_webinar .div-content .subtitulo-secundario, #activity_consultoria .div-content .subtitulo-secundario{
        font-weight: 400;
        color: var(--gray);
    }
    #activity_webinar .div-content .div-abstract, #activity_consultoria .div-content .div-abstract {
        /*font-family: 'Quicksand', sans-serif;*/
        font-weight: 500;
    }
    #activity_webinar .div-local, #activity_consultoria .div-local {
        font-size: 1.125rem;
        font-weight: 600;
        /*color: var(--blue);*/
        line-height: normal;
        line-height: initial;
        background-color: var(--gray-light);
    }
    #activity_webinar .div-local i, #activity_consultoria .div-local i {
        color: #565656;
        margin-right: 1rem;
    }
    #activity_webinar .div-text, #activity_consultoria .div-text {
        line-height: 1.75;
    }
    #activity_webinar .div-last .div-text, #activity_consultoria .div-last .div-text {
        line-height: 1.25;
    }
    #activity_webinar .div-text li, #activity_consultoria .div-text li {
        padding-left: 0.25rem;
    }
    #activity_webinar .div-text li::marker, #activity_consultoria .div-text li::marker {
        font-weight: 600;
    }
    #webinar-cards .ico-svg, #activity_webinar .div-last .ico-svg, #activity_consultoria .div-last .ico-svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
    #activity_webinar .div-last .div-source span:not(:last-child):after, #activity_consultoria .div-last .div-source span:not(:last-child):after{
        padding: 0 0.25rem;
    }
/*  end ACTIVITIES */



