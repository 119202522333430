footer .row {
	justify-content: center;
}

footer .address {
	cursor: default;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
footer .h6, footer h6 {
    font-size: 0.875rem;
	line-height: 1.5;
}

footer span, footer a {
	font-size: 0.75rem;
	display: flex;
	align-items: flex-start;
}

footer span.logosfooter {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
}
span.logos, footer span.logosfooter.redes {
    justify-content: flex-start;
    column-gap: 15px;
}
footer .copyright span {
    display: inherit;
}

.current_bottom{
    text-decoration: underline;
}

img.light-logo.svg-logo.img-fluid {
	filter: brightness(0) invert(1);
}
